import React from "react";
import Calendar2 from "./Calendar";

const About4 = (props) => {
  return (
    <>
      <section className="relative pt-[50px] pb-[50px] lg:pt-[50px]">
        <div className="container mx-auto">
          <div className="-mx-4 flex flex-wrap items-center">
            <div className="w-full px-4 lg:w-1/3">
              <div className="mb-12 max-w-[540px] lg:mb-0">
                <h2 className="mb-5 text-3xl font-bold leading-tight text-black sm:text-[40px] sm:leading-tight md:text-[45px] md:leading-tight lg:text-[38px] lg:leading-tight xl:text-[45px] xl:leading-tight">
                  {props.title}
                </h2>
                <p className="text-body-color mb-10 text-base font-medium leading-relaxed">
                  {props.paragraph}
                </p>
                <h3 className="mb-8 text-2xl font-bold text-black">
                  {props.h3}
                </h3>
                <ul className="pb-6">
                  <li className="text-body-color mb-4 flex text-base">
                    <span className="bg-primary mr-2 mt-2 flex h-2 w-full max-w-[8px] items-center justify-center rounded-full text-base"></span>
                    {props.bullet1}
                    
                  </li>
                  <li className="text-body-color mb-4 flex text-base">
                    <span className="bg-primary mr-2 mt-2 flex h-2 w-full max-w-[8px] items-center justify-center rounded-full text-base"></span>
                    {props.bullet2}
                  </li>
                </ul>
                <a href="#table" className="bg-primary inline-flex items-center justify-center rounded-md py-3 px-6 text-center text-base font-medium text-white hover:bg-opacity-90 sm:py-4 sm:px-9">
                  Know More
                </a>
              </div>
            </div>
            <div className="w-full px-4 lg:w-2/3">

                     <Calendar2
                        tableData={props.tableData}
                     />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About4;
