import { VectorMap } from "react-jvectormap";
import { useRef } from "react";
import countries from './Map/countries.json';

const servicesOffered = {};

for (const countryCode in countries) {
  if (Object.hasOwnProperty.call(countries, countryCode)) {
    if (countries[countryCode].Type !== "We do not offer services here") {
      servicesOffered[countryCode] = 100;
    }
  }
}

export default function App() {
  const mapRef = useRef();
  return (
    <div style={{ padding: "40px" }}>
      <div style={{ width: "100%", height: "75vh" }}>
        <VectorMap
          ref={mapRef}
          zoomOnScroll={false}
          zoomButtons={false}
          map={"world_mill"}
          backgroundColor="white"
          containerStyle={{
            width: "100%",
            height: "100%"
          }}
          markerStyle={{
            initial: {
              fill: "red",
              stroke: "#383f47"
            }
          }}
          containerClassName="map"
          series={{
            regions: [
              {
                scale: ["#4E81BD"],
                attribute: "fill",
                values: servicesOffered,
                normalizeFunction: "polynomial",
                min: 0,
                max: 100
              }
            ]
          }}

          regionStyle={{
            initial: {
              fill: "#D1D5DB",
              "fill-opacity": 1,
              stroke: "#265cff",
              "stroke-width": 0,
              "stroke-opacity": 0
            },
            hover: {
              "fill-opacity": 1,
              fill: "",
              stroke: "#2b2b2b"
            },
            selected: {
              fill: "#E8E8E8"
            }
          }}
          onRegionTipShow={function (event, label, code, ...props) {
            const info = countries[code];
            console.log(code)
            label.html(
              '<div style="background-color: #E8E8E8; border: 1px solid #E8E8E8; outline: 10px solid #E8E8E8; border-radius: 6px; min-height: 70px; width: 275px; color: black; padding-left: 10px; position: absolute; bottom: 0px; left: 0;">' +
              "<p>" +
                "<b>" +
                info.Country + // Displaying the country name
                "</b>" +
                "</p>" +
                "<p>" +
                "Exchange: " + info.Exchange + // Displaying the Exchange information
                "</p>" +
                "<p>" +
                "Type: " + info.Type + // Displaying the Type information
                "</p>" +
                "<p>" +
                "Custody: " + info.custodyAvailable + // Displaying Custody Available information
                "</p>" +
                "<p>" +
                "Cash Equity Trading: " + info.CashEquityTradingAvailable + // Displaying Cash Equity Trading Available information
                "</p>" +
                "<p>" +
                "CFD Trading: " + info.CFDTradingAvailable + // Displaying CFD Trading Available information
                "</p>" +
                "<p>" +
                "</div>"
            );          
          }}
        />
      </div>
    </div>
  );
}
