import SEO from './Components/SEO';
import React from 'react'
import Navbar4 from './Components/Nav4';
import Header from './Components/Hero'
import Map from './Components/Map'
import Announcements from './Components/Annoucements'
import Footer2 from './Components/Footer';
import Map3 from './Components/Map3';
import Table7 from './Components/OffshoreTable2';

function Market(){
    return(
        <>
            <SEO
                title={'Peresec Offshore Markets'}
                description={'Discover the offshore markets that Peresec offers.'}
            />
            <Navbar4/>
            <Header 
                title={'Market information'}
                paragraph={'Hover over the countries on the map below to learn more about our trading services in each jurisdiction'}
            />            
            <Map3/>
            <Table7/>
            <Announcements/>
            <Footer2/>
        </>    
    )
}

export default Market;