import SEO from './Components/SEO'
import React from 'react'
import Header from './Components/Hero'
import Navbar4 from './Components/Nav4'
import Table from './Components/CorporateActionsTable'
import About4 from './Components/CalendarAbout'
import Footer2 from './Components/Footer'
import Announcements from './Components/Annoucements'


function Actions(){
    return(
        <div>
            <SEO
                title={'Corporate Actions'}
                description={'Discover the corporate action events for 2023'}
            />
            <Navbar4/>
            <Header 
                title={'Corporate Actions'}
                paragraph={'Discover the corporate action events from around the world that may impact your trading'}
            />
            <Table/>
            <Announcements/>
            <Footer2/>
        </div>
    )
}

export default Actions;