import logo from './logo.svg';
import './App.css';
import Home from './Home'
import Holidays from './bank-holidays-2023';
import Actions from './corporate-actions';
import Markets from './peresec-offshore-markets';
import Tax from './withholding-tax';
import News from './corporate-news';
import OzowSuccess from './OzowSuccess';
import OzowFail from './OzowFail';
import OzowError from './OzowError';
import OzowTest from './OzowTest';


import {Routes, Route} from 'react-router-dom'
import Pricing from './pricing';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Home />}/>
        <Route exact path="/bank-holidays-2023" element={<Holidays />}/>
        <Route exact path="/pricing" element={<Pricing />}/>
        <Route exact path="/peresec-offshore-markets" element={<Markets />}/>
        <Route exact path="/withholding-tax" element={<Tax />}/>
        <Route exact path="/corporate-actions" element={<Actions />}/>
        <Route exact path="/corporate-news" element={<News />}/>
        <Route exact path="/OzowSuccess" element={<OzowSuccess />}/>
        <Route exact path="/OzowFail" element={<OzowFail />}/>
        <Route exact path="/OzowError" element={<OzowError />}/>
        <Route exact path="/OzowTest" element={<OzowTest />}/>

      </Routes>
    </div>
  );
}

export default App;
