import SEO from './Components/SEO'
import React from 'react'
import Header from './Components/Hero'
import Navbar4 from './Components/Nav4'
import Table from './Components/Table'
import About4 from './Components/CalendarAbout'
import Footer2 from './Components/Footer'
import Announcements from './Components/Annoucements'

function Holidays(){
    return(
        <div>
            <SEO
                title={'Corporate news events'}
                description={'Discover the news events and announcements relevant to your portfolio'}
            />
            <Navbar4/>
            <Header 
                title={'Corporate news events and announcements'}
                paragraph={'Discover the news events and announcements relevant to your portfolio'}
            />
            <Announcements/>
            <Footer2/>
        </div>
    )
}

export default Holidays;