import React, { useState } from 'react'


const headers = [
  { name: 'Query', styles: 'min-w-[100px]', icon: false },
  { name: 'Details', styles: 'min-w-[250px]', icon: false },
]

const Table7 = (
    { selectedCountryData }
) => {
    const tableData = [
        { attribute: 'Type of clients', value: selectedCountryData.Type },
        { attribute: 'Exchanges', value: selectedCountryData.Exchange },
        { attribute: 'Custody', value: selectedCountryData.custodyAvailable },
        { attribute: 'Cash Equity', value: selectedCountryData.CashEquityTradingAvailable },
        { attribute: 'CFD', value: selectedCountryData.CFDTradingAvailable },
        { attribute: 'Fee Type', value: selectedCountryData.FeeType },
        { attribute: 'If fee is %', value: selectedCountryData.IsFeePerc },
        { attribute: 'Fee Value', value: selectedCountryData.FeeValue },
        { attribute: 'Fee on buy', value: selectedCountryData.FeeBuy },
        { attribute: 'Fee on sell', value: selectedCountryData.FeeBuy },
        { attribute: 'Minimum Tx Amount', value: selectedCountryData.MinimumTxAmt },
        { attribute: 'Consideration barrier', value: selectedCountryData.considerationBarrier },
        { attribute: 'Barrier charge', value: selectedCountryData.barrierCharge },
        { attribute: 'Cash equity fee', value: selectedCountryData.CashEquityFee },
        { attribute: 'CFD fee', value: selectedCountryData.CFDFee },
      ];    
  return (
    <section >
      <div>
        <div className='rounded-md border border-stroke bg-white'>
          <div className='max-w-full overflow-x-auto'>
            <table className='w-full table-auto'>
              <TableHead headers={headers} />
              <TableBody data={tableData} />
            </table>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Table7;



const TableHead = ({ headers }) => {
  return (
    <thead>
      <tr>
        {headers.map((header, index) => (
          <th
            className='border border-stroke border-t-transparent py-3 px-5 first:border-l-transparent last:border-r-transparent'
            key={index}
          >
            <div
              className={`flex items-center justify-between ${header.styles}`}
            >
              <span className='text-sm font-medium text-body-color'>
                {header.name}
              </span>
              {header.icon && <IconOption />}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  )
}

const TableBody = ({ data }) => {
    return (
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            <td className='border border-stroke py-0 px-5'>
              <p className='text-base text-body-color'>{row.attribute}</p>
            </td>
            <td className='border border-stroke py-0 px-5'>
              <p className='text-base text-body-color'>{row.value}</p>
            </td>
          </tr>
        ))}
      </tbody>
    );
  };
  
const IconOption = () => {
  return (
    <span className='cursor-pointer'>
      <svg
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.08301 3.49935C4.08301 3.17718 4.34418 2.91602 4.66634 2.91602H12.2497C12.5718 2.91602 12.833 3.17718 12.833 3.49935C12.833 3.82152 12.5718 4.08268 12.2497 4.08268H4.66634C4.34418 4.08268 4.08301 3.82152 4.08301 3.49935Z'
          fill='#637381'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.08301 6.99935C4.08301 6.67718 4.34418 6.41602 4.66634 6.41602H12.2497C12.5718 6.41602 12.833 6.67718 12.833 6.99935C12.833 7.32151 12.5718 7.58268 12.2497 7.58268H4.66634C4.34418 7.58268 4.08301 7.32151 4.08301 6.99935Z'
          fill='#637381'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.08301 10.4993C4.08301 10.1772 4.34418 9.91602 4.66634 9.91602H12.2497C12.5718 9.91602 12.833 10.1772 12.833 10.4993C12.833 10.8215 12.5718 11.0827 12.2497 11.0827H4.66634C4.34418 11.0827 4.08301 10.8215 4.08301 10.4993Z'
          fill='#637381'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.16699 3.49935C1.16699 3.17718 1.42816 2.91602 1.75033 2.91602H1.75616C2.07832 2.91602 2.33949 3.17718 2.33949 3.49935C2.33949 3.82152 2.07832 4.08268 1.75616 4.08268H1.75033C1.42816 4.08268 1.16699 3.82152 1.16699 3.49935Z'
          fill='#637381'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.16699 6.99935C1.16699 6.67718 1.42816 6.41602 1.75033 6.41602H1.75616C2.07832 6.41602 2.33949 6.67718 2.33949 6.99935C2.33949 7.32151 2.07832 7.58268 1.75616 7.58268H1.75033C1.42816 7.58268 1.16699 7.32151 1.16699 6.99935Z'
          fill='#637381'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.16699 10.4993C1.16699 10.1772 1.42816 9.91602 1.75033 9.91602H1.75616C2.07832 9.91602 2.33949 10.1772 2.33949 10.4993C2.33949 10.8215 2.07832 11.0827 1.75616 11.0827H1.75033C1.42816 11.0827 1.16699 10.8215 1.16699 10.4993Z'
          fill='#637381'
        />
      </svg>
    </span>
  )
}

