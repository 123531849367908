import React, { useState } from 'react'
import countries from './Map/countries.json'

const servicesOffered = {};

for (const countryCode in countries) {
  if (Object.hasOwnProperty.call(countries, countryCode)) {
    if (countries[countryCode].Type !== "We do not offer services here") {
      servicesOffered[countryCode] = countries[countryCode];
    }
  }
}

const headers = [
    { name: 'Query', styles:  'min-w-[150px]', icon: false },
    { name: 'Description', styles: 'min-w-[250px]', icon: false },
    { name: 'Details', styles: 'min-w-[150px]', icon: false },
  ]
const Table7 = () => {
    const [selectedCountryCode, setSelectedCountryCode] = useState('AU');

    const handleChangeCountry = (e) => {
      setSelectedCountryCode(e.target.value);
    };

  return (
    <section className='bg-gray-2 py-20 lg:py-[120px]'>
      <div className='mx-auto px-4 md:container'>
      <div className="w-full px-4">
      <div className="justify-between sm:flex">
                <div className="mb-2">
                  <h3 className="text-lg font-bold leading-none text-black sm:text-xl mb-2">
                    Find out more about our country offerings
                  </h3>
                  <p className="text-sm text-body-color sm:text-base mb-2">
                    Click on a country in the drop down to find out about the trading information of that particular country such as exchange, fee and other data
                  </p>
                </div>
              </div>
          </div>
      <TableTop
        selectedCountryCode={selectedCountryCode}
        handleChangeCountry={handleChangeCountry}
      />        
      <div className='rounded-md border border-stroke bg-white'>
          <div className='max-w-full overflow-x-auto'>
            <table className='w-full table-auto'>
              <TableHead headers={headers} />
              <TableBody 
                selectedCountryCode={selectedCountryCode}
              />
            </table>
          </div>
          <TableBottom
            buttonComplete='Learn more'
          />
        </div>
      </div>
    </section>
  )
}

export default Table7;

const TableTop = ({ selectedCountryCode, handleChangeCountry }) => {
    const countryCodes = Object.keys(servicesOffered);
  
    const countryOptions = countryCodes.map((countryCode, index) => (
      <option key={index} value={countryCode}>
        {servicesOffered[countryCode].Country}
      </option>
    ));

  return (
    <div className='mb-5 flex items-center rounded-md border border-stroke bg-white py-3 px-6 w-1/2'>
      <div className='relative w-full'>
        <select
          name='country'
          id='country'
          className='w-full appearance-none border-r border-stroke pr-12 text-base font-medium text-body-color outline-none' // Set width to 100% to fill the container
          value={selectedCountryCode}
          onChange={handleChangeCountry}
        >
          {countryOptions}
        </select>
        <span className='absolute right-4 top-1/2 -translate-y-1/2'>
          <svg
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M3.96967 6.21967C4.26256 5.92678 4.73744 5.92678 5.03033 6.21967L9 10.1893L12.9697 6.21967C13.2626 5.92678 13.7374 5.92678 14.0303 6.21967C14.3232 6.51256 14.3232 6.98744 14.0303 7.28033L9.53033 11.7803C9.23744 12.0732 8.76256 12.0732 8.46967 11.7803L3.96967 7.28033C3.67678 6.98744 3.67678 6.51256 3.96967 6.21967Z'
              fill='#637381'
            />
          </svg>
        </span>
      </div>
    </div>
  )
}

const TableHead = ({ headers }) => {
  return (
    <thead>
      <tr>
        {headers.map((header, index) => (
          <th
            className='border border-stroke border-t-transparent py-3 px-5 first:border-l-transparent last:border-r-transparent'
            key={index}
          >
            <div
              className={`flex items-center justify-between ${header.styles}`}
            >
              <span className='text-lg font-semibold text-primary'>
                {header.name}
              </span>
              {header.icon && <IconOption />}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  )
}

const TableBody = ({ data, selectedCountryCode }) => {
    const selectedCountryData = servicesOffered[selectedCountryCode]
    const tableData = [
        { attribute: 'Type of clients', value: selectedCountryData.Type , description: 'The client relationship can be trading and custody (controlled client) or execution only (non-controlled client)'},
        { attribute: 'Exchanges', value: selectedCountryData.Exchange, description: 'A list of available exchanges, multilateral trading facilities, and other execution venues accessible in this market.' },
        { attribute: 'Custody', value: selectedCountryData.custodyAvailable, description: 'Indicates if custody services are available from Peresec for assets in this market.' },
        { attribute: 'Cash Equity', value: selectedCountryData.CashEquityTradingAvailable, description: 'Specifies if trading of cash-settled equities is supported in this market.' },
        { attribute: 'CFD', value: selectedCountryData.CFDTradingAvailable, description: 'Notes whether contract for difference (CFD) trading is accessible for this market.' },
        { attribute: 'Fee Type', value: selectedCountryData.FeeType, description: 'Denotes if fees in this market are quoted in percentage terms or fixed monetary amounts per trade.' },
        { attribute: 'If fee is %', value: selectedCountryData.IsFeePerc, description: 'Indicates if percentage-based fees are applied.' },
        { attribute: 'Fee Value', value: selectedCountryData.FeeValue, description: 'Specifies the percentage or fixed fee amount per trade for this market.' },
        { attribute: 'Fee on buy', value: selectedCountryData.FeeBuy, description: 'Shows if a separate buy-side commission or fee is charged.' },
        { attribute: 'Fee on sell', value: selectedCountryData.FeeSell, description: 'Shows if a separate sell-side commission or fee is charged.' },
        { attribute: 'Minimum Tx Amount', value: selectedCountryData.MinimumTxAmt, description: 'The minimum order size or notional amount accepted for trading in this market.' },
        { attribute: 'Consideration barrier', value: selectedCountryData.considerationBarrier, description: 'Indicates if a minimum consideration threshold applies in this market.' },
        { attribute: 'Barrier charge', value: selectedCountryData.barrierCharge, description: 'The monetary amount of the consideration barrier fee if applicable.' },
        { attribute: 'Cash equity fee', value: selectedCountryData.CashEquityFee, description: 'The percentage or fixed fee for cash equities trading in this market if different than the standard fee.' },
        { attribute: 'CFD fee', value: selectedCountryData.CFDFee, description: 'The percentage or fixed fee for CFD trading in this market if different than the standard fee.' },
      ]; 

      return (
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index}>
              <td className='border border-stroke py-1 px-5'>
                <p className='text-base text-body-color'>{row.attribute}</p>
              </td>
              <td className='border border-stroke py-1 px-5'>
                <p className='text-base text-body-color'>{row.value}</p>
              </td>
              <td className='border border-stroke py-1 px-5'>
                <p className='text-base text-body-color'>{row.description}</p>
            </td>
            </tr>
          ))}
        </tbody>
      );
    };

const TableBottom = ({ buttonComplete }) => {
  return (
    <div className='items-center justify-between p-5 sm:flex sm:px-6'>
      <p className='mb-4 text-right text-base font-medium text-body-color sm:mb-0 sm:text-left md:text-lg'>
      </p>
      <div className='flex items-center justify-end'>
        <a href="#contact" className='mr-3 py-[10px] text-base font-medium text-body-color hover:text-primary sm:mr-5'>
        </a>
        <a href="#contact" className='rounded bg-primary py-[10px] px-3 text-sm font-semibold text-white hover:bg-opacity-90 sm:px-5 sm:text-base'>
          {buttonComplete}
        </a>
      </div>
    </div>
  )
}

const IconOption = () => {
  return (
    <span className='cursor-pointer'>
      <svg
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.08301 3.49935C4.08301 3.17718 4.34418 2.91602 4.66634 2.91602H12.2497C12.5718 2.91602 12.833 3.17718 12.833 3.49935C12.833 3.82152 12.5718 4.08268 12.2497 4.08268H4.66634C4.34418 4.08268 4.08301 3.82152 4.08301 3.49935Z'
          fill='#637381'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.08301 6.99935C4.08301 6.67718 4.34418 6.41602 4.66634 6.41602H12.2497C12.5718 6.41602 12.833 6.67718 12.833 6.99935C12.833 7.32151 12.5718 7.58268 12.2497 7.58268H4.66634C4.34418 7.58268 4.08301 7.32151 4.08301 6.99935Z'
          fill='#637381'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.08301 10.4993C4.08301 10.1772 4.34418 9.91602 4.66634 9.91602H12.2497C12.5718 9.91602 12.833 10.1772 12.833 10.4993C12.833 10.8215 12.5718 11.0827 12.2497 11.0827H4.66634C4.34418 11.0827 4.08301 10.8215 4.08301 10.4993Z'
          fill='#637381'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.16699 3.49935C1.16699 3.17718 1.42816 2.91602 1.75033 2.91602H1.75616C2.07832 2.91602 2.33949 3.17718 2.33949 3.49935C2.33949 3.82152 2.07832 4.08268 1.75616 4.08268H1.75033C1.42816 4.08268 1.16699 3.82152 1.16699 3.49935Z'
          fill='#637381'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.16699 6.99935C1.16699 6.67718 1.42816 6.41602 1.75033 6.41602H1.75616C2.07832 6.41602 2.33949 6.67718 2.33949 6.99935C2.33949 7.32151 2.07832 7.58268 1.75616 7.58268H1.75033C1.42816 7.58268 1.16699 7.32151 1.16699 6.99935Z'
          fill='#637381'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.16699 10.4993C1.16699 10.1772 1.42816 9.91602 1.75033 9.91602H1.75616C2.07832 9.91602 2.33949 10.1772 2.33949 10.4993C2.33949 10.8215 2.07832 11.0827 1.75616 11.0827H1.75033C1.42816 11.0827 1.16699 10.8215 1.16699 10.4993Z'
          fill='#637381'
        />
      </svg>
    </span>
  )
}

const Checkbox = ({ name, index }) => {
  const [isChecked, setIsChecked] = useState(false)

  return (
    <div className='relative'>
      <input
        type='checkbox'
        name='tableCheckbox'
        id={index}
        className='tableCheckbox sr-only'
        onChange={() => {
          setIsChecked(!isChecked)
        }}
      />
      <label
        htmlFor={index}
        className='flex cursor-pointer items-center text-base text-body-color'
      >
        <span
          className={`icon-box mr-3 flex h-4 w-4 items-center justify-center rounded-[3px] border-[.5px] text-white ${
            isChecked ? '!bg-primary border-primary' : 'bg-gray-2 border-stroke'
          }`}
        >
          <svg
            width='10'
            height='10'
            viewBox='0 0 10 10'
            className={`icon ${isChecked ? '' : 'opacity-0'}`}
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M8.62796 2.20602C8.79068 2.36874 8.79068 2.63256 8.62796 2.79528L4.04463 7.37861C3.88191 7.54133 3.61809 7.54133 3.45537 7.37861L1.37204 5.29528C1.20932 5.13256 1.20932 4.86874 1.37204 4.70602C1.53476 4.5433 1.79858 4.5433 1.96129 4.70602L3.75 6.49473L8.03871 2.20602C8.20142 2.0433 8.46524 2.0433 8.62796 2.20602Z'
              fill='currentColor'
            />
          </svg>
        </span>
        <span className='pr-3'>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M11.1034 3.81714C11.4703 3.07397 12.53 3.07397 12.8968 3.81714L14.8577 7.7896C15.0032 8.08445 15.2844 8.28891 15.6098 8.33646L19.9964 8.97763C20.8163 9.09747 21.1431 10.1053 20.5495 10.6835L17.3769 13.7735C17.1411 14.0033 17.0334 14.3344 17.0891 14.6589L17.8376 19.0231C17.9777 19.8401 17.1201 20.4631 16.3865 20.0773L12.4656 18.0153C12.1742 17.8621 11.8261 17.8621 11.5347 18.0153L7.61377 20.0773C6.88014 20.4631 6.02259 19.8401 6.16271 19.0231L6.91122 14.6589C6.96689 14.3344 6.85922 14.0033 6.62335 13.7735L3.45082 10.6835C2.85722 10.1053 3.18401 9.09747 4.00392 8.97763L8.39051 8.33646C8.71586 8.28891 8.99704 8.08445 9.14258 7.7896L11.1034 3.81714Z'
              fill='#FFD02C'
            />
          </svg>
        </span>
        {name}
      </label>
    </div>
  )
}
