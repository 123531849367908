import React, { useEffect, useState } from "react";
import jsVectorMap from "jsvectormap";
import "jsvectormap/dist/css/jsvectormap.css";
import "jsvectormap/dist/maps/world";
import countries from './Map/countries.json'
import Table7 from "./DwtTable";

const uniqueCountryCodes = [];

for (const countryCode in countries) {
  if (Object.hasOwnProperty.call(countries, countryCode)) {
    if (countries[countryCode].Type !== "We do not offer services here") {
      if (!uniqueCountryCodes.includes(countryCode)) {
        uniqueCountryCodes.push(countryCode);
      }
    }
  }
}

const Map2 = () => {
  const [code, setCode] = useState('ZA');
  const [selectedCountryData, setSelectedCountryData] = useState({}); // Initialize selectedCountryData
  console.log(code)
  let mapInstance = null; // Initialize a variable to store the map instance

  useEffect(() => {
    // Check if the mapInstance has already been created
    if (!mapInstance) {
      mapInstance = new jsVectorMap({
        selector: "#mapTwo",
        map: "world",
        zoomButtons: true,
        regionStyle: {
          initial: {
            fill: "#E8E8E8",
          },
          hover: {
            fillOpacity: 1,
            fill: "#DBDBDB",
          },
          selected: {
            fill: "#primary", // Change the fill color for selected regions
            stroke: "#E8E8E8", // Change the stroke color for selected regions
          },
        },
        selectedRegions: uniqueCountryCodes,
        regionLabelStyle: {
          initial: {
            fontFamily: "inter",
            fontWeight: "semibold",
            fill: "#fffff",
          },
          hover: {
            cursor: "pointer",
          },
        },
        
        labels: {
          regions: {
            render(code) {
              let country = code.split("-")[1]; // Use 'regionCode' here
            },
          },
        },
        onRegionClick: function (event, code) {
          setCode(code); // Set the correct code
        },
      });
    }

    // Clean up the map when the component unmounts
    return () => {
      if (mapInstance) {
        mapInstance.destroy();
        mapInstance = null; // Reset the map instance
        }
      };
    }, []);

    // Function to get country data based on the country code
    const getCountryData = (countryCode) => {
      return countries[countryCode] || {};
    };
  
    // Get country information based on the selected code
    useEffect(() => {
      const countryData = getCountryData(code);
      setSelectedCountryData(countryData);
    }, [code]);

  return (
    <>
      <section className="relative z-10 overflow-hidden bg-white py-20 lg:py-[110px]">
        <div className="container mx-auto">
        <div className="justify-between sm:flex">
                <div className="mb-2">
                  <h3 className="text-lg font-bold leading-none text-black sm:text-xl mb-2">
                    See where we provide market services
                  </h3>
                  <p className="text-sm text-body-color sm:text-base mb-2">
                    Click on a country on the map to find out more about the trading information of that particular country such as exchange, fee and other data
                  </p>
                </div>
              </div>
          <div className="-mx-4 flex flex-wrap">
            
            <div className="w-full px-4 lg:w-1/2">
              <div className="bg-primary relative z-10 mb-12 py-12 px-5 sm:px-12 lg:mb-0 2xl:p-[70px]">
              {Object.keys(selectedCountryData).length > 0 ? (
                <div>
                <h2 className="mb-6 text-xl font-bold text-white">
                {selectedCountryData.Country}
                </h2>
                <Table7 selectedCountryData={selectedCountryData} />
                  </div>
                ) : (
                  <div>
                  <h2 className="mb-6 text-xl font-bold text-white">
                  Select a highlighted country for market info
                  </h2>
                    <Table7 selectedCountryData={selectedCountryData} />
                    </div>
                )}

                <div className="items-end justify-between md:flex lg:block xl:flex">
                  <div className="pt-4 md:text-right lg:text-left xl:text-right">
                    <a
                      href="/persec-offshore-markets"
                      className="flex items-center text-base font-semibold text-white"
                    >
                      Learn More
                      <span className="pl-2">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.2063 6.44063C12.0094 6.24375 11.7001 6.24375 11.5032 6.44063C11.3063 6.6375 11.3063 6.94688 11.5032 7.14375L12.8532 8.52188H4.5563C4.27505 8.52188 4.05005 8.74688 4.05005 9.02813C4.05005 9.30938 4.27505 9.53438 4.5563 9.53438H12.8532L11.5032 10.9125C11.3063 11.1094 11.3063 11.4188 11.5032 11.6156C11.5876 11.7 11.7282 11.7563 11.8407 11.7563C11.9813 11.7563 12.0938 11.7 12.1782 11.6156L13.725 10.0406C14.2594 9.50625 14.2594 8.63438 13.725 8.1L12.2063 6.44063Z"
                            fill="white"
                          />
                          <path
                            d="M9.00003 0.365723C4.21878 0.365723 0.337524 4.21885 0.337524 9.0001C0.337524 13.7813 4.21878 17.6626 9.00003 17.6626C13.7813 17.6626 17.6625 13.7813 17.6625 9.0001C17.6625 4.21885 13.7813 0.365723 9.00003 0.365723ZM9.00003 16.6782C4.78128 16.6782 1.3219 13.2188 1.3219 9.0001C1.3219 4.78135 4.78128 1.3501 9.00003 1.3501C13.2188 1.3501 16.6782 4.78135 16.6782 9.0001C16.6782 13.2188 13.2188 16.6782 9.00003 16.6782Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
                <span className="absolute top-4 right-4 -z-10">
                  <svg
                    width="33"
                    height="47"
                    viewBox="0 0 33 47"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="16.6667"
                      cy="30.9998"
                      r="1.66667"
                      transform="rotate(-90 16.6667 30.9998)"
                      fill="white"
                    />
                    <circle
                      cx="1.99992"
                      cy="30.9998"
                      r="1.66667"
                      transform="rotate(-90 1.99992 30.9998)"
                      fill="white"
                    />
                    <circle
                      cx="30.9999"
                      cy="30.9998"
                      r="1.66667"
                      transform="rotate(-90 30.9999 30.9998)"
                      fill="white"
                    />
                    <circle
                      cx="16.6667"
                      cy="16.3333"
                      r="1.66667"
                      transform="rotate(-90 16.6667 16.3333)"
                      fill="white"
                    />
                    <circle
                      cx="1.99992"
                      cy="16.3333"
                      r="1.66667"
                      transform="rotate(-90 1.99992 16.3333)"
                      fill="white"
                    />
                    <circle
                      cx="30.9999"
                      cy="16.3333"
                      r="1.66667"
                      transform="rotate(-90 30.9999 16.3333)"
                      fill="white"
                    />
                    <circle
                      cx="16.6667"
                      cy="45.3333"
                      r="1.66667"
                      transform="rotate(-90 16.6667 45.3333)"
                      fill="white"
                    />
                    <circle
                      cx="16.6667"
                      cy="1.66683"
                      r="1.66667"
                      transform="rotate(-90 16.6667 1.66683)"
                      fill="white"
                    />
                    <circle
                      cx="1.99992"
                      cy="45.3333"
                      r="1.66667"
                      transform="rotate(-90 1.99992 45.3333)"
                      fill="white"
                    />
                    <circle
                      cx="1.99992"
                      cy="1.66683"
                      r="1.66667"
                      transform="rotate(-90 1.99992 1.66683)"
                      fill="white"
                    />
                    <circle
                      cx="30.9999"
                      cy="45.3333"
                      r="1.66667"
                      transform="rotate(-90 30.9999 45.3333)"
                      fill="white"
                    />
                    <circle
                      cx="30.9999"
                      cy="1.66683"
                      r="1.66667"
                      transform="rotate(-90 30.9999 1.66683)"
                      fill="white"
                    />
                  </svg>
                </span>
              </div>
            </div>
            
            <div className="w-full px-4 lg:w-1/2">
              <div className="flex h-full w-full items-center justify-center">
                
              <div id="mapTwo" className="mapTwo"></div>
              
              </div>
            </div>
          </div>
        </div>
        <div
        >              

        </div>
      </section>
      <style>
        {`
        .jvm-zoom-btn{
          top: auto;
          bottom: 0px;
          left: auto;
          display: flex;
          height: 2rem;
          width: 2rem;
          align-items: center;
          justify-content: center;
          border-radius: 0.25rem;
          border-width: .5px;
          --tw-border-opacity: 1;
          border-color: rgb(231 231 231 / var(--tw-border-opacity));
          --tw-bg-opacity: 1;
          background-color: rgb(244 247 255 / var(--tw-bg-opacity));
          font-weight: 600;
          line-height: 1;
          --tw-text-opacity: 1;
          color: rgb(99 115 129 / var(--tw-text-opacity));
        }
        
        .jvm-zoom-btn:hover{
          --tw-border-opacity: 1;
          border-color: rgb(48 86 211 / var(--tw-border-opacity));
          --tw-bg-opacity: 1;
          background-color: rgb(48 86 211 / var(--tw-bg-opacity));
          --tw-text-opacity: 1;
          color: rgb(255 255 255 / var(--tw-text-opacity));
        }
        .mapTwo .jvm-zoom-btn{
          top: auto;
          bottom: 0px;
        }
        
        .mapTwo .jvm-zoom-btn.jvm-zoomin{
          left: 0px;
        }
        
        .mapTwo .jvm-zoom-btn.jvm-zoomout{
          left: 2.25rem;
        }
        `}
      </style>
    </>
  );
};

export default Map2;

