import React from "react";

const Fail = () => {
  return (
    <section className="flex min-h-screen items-center justify-center bg-gray py-20 dark:bg-dark lg:py-[120px]">
      <div className="container mx-auto">
        <div className="mx-auto max-w-[600px] rounded-[10px] bg-white p-10 text-center shadow-1 dark:bg-dark-2 dark:shadow-card md:px-[70px] md:py-[55px]">
          <div className="mx-auto mb-14 text-center">
            <div className="mx-auto w-full max-w-full flex h-10 w-20 items-center justify-center rounded-full bg-white/10 text-white">
            <svg
       width="48"
       height="48"
               viewBox="0 0 24 24"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
               >
               <path
                  d="M22.6875 15.4875L14.6625 2.57498C14.025 1.71248 13.05 1.22498 12 1.22498C10.9125 1.22498 9.93753 1.71248 9.33753 2.57498L1.31253 15.4875C0.562527 16.5 0.450027 17.8125 1.01253 18.9375C1.57503 20.0625 2.70003 20.775 3.97503 20.775H20.025C21.3 20.775 22.425 20.0625 22.9875 18.9375C23.55 17.85 23.4375 16.5 22.6875 15.4875ZM21.4875 18.1875C21.1875 18.75 20.6625 19.0875 20.025 19.0875H3.97503C3.33753 19.0875 2.81253 18.75 2.51253 18.1875C2.25003 17.625 2.28753 16.9875 2.66253 16.5L10.6875 3.58748C10.9875 3.17498 11.475 2.91248 12 2.91248C12.525 2.91248 13.0125 3.13748 13.3125 3.58748L21.3375 16.5C21.7125 16.9875 21.75 17.625 21.4875 18.1875Z"
                  fill="red"
                  />
               <path
                  d="M12 8.20001C11.55 8.20001 11.1375 8.57501 11.1375 9.06251V13.15C11.1375 13.6 11.5125 14.0125 12 14.0125C12.4875 14.0125 12.8625 13.6375 12.8625 13.15V9.02501C12.8625 8.57501 12.45 8.20001 12 8.20001Z"
                  fill="red"
                  />
               <path
                  d="M12 15C11.55 15 11.1375 15.375 11.1375 15.8625V16.05C11.1375 16.5 11.5125 16.9125 12 16.9125C12.4875 16.9125 12.8625 16.5375 12.8625 16.05V15.825C12.8625 15.375 12.45 15 12 15Z"
                  fill="red"
                  />
            </svg>
          </div>
          </div>
          <h2 className="mb-3 text-2xl font-semibold text-dark dark:text-white sm:text-3xl">
            Your payment was unsuccessful.
          </h2>
          <p className="mb-5 text-base text-body-color dark:text-dark-6">
            This message is to notify you that your payment to Peresec was unsuccessful. Please return to the payment page and retry on the link below.        
          </p>
          <a
            href="https://ask.peresec.com/"
            className="inline-flex items-center rounded-md bg-white px-6 py-3 text-base font-medium text-primary shadow-1 hover:bg-gray-2 dark:bg-white/5 dark:shadow-card dark:hover:bg-white/10"
          >
            Go Back to To The Payment Page
            <span className="pl-2">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 9.5L11.5312 2.9375C11.25 2.65625 10.8125 2.65625 10.5312 2.9375C10.25 3.21875 10.25 3.65625 10.5312 3.9375L15.7812 9.28125H2.5C2.125 9.28125 1.8125 9.59375 1.8125 9.96875C1.8125 10.3437 2.125 10.6875 2.5 10.6875H15.8437L10.5312 16.0938C10.25 16.375 10.25 16.8125 10.5312 17.0938C10.6562 17.2188 10.8437 17.2813 11.0312 17.2813C11.2187 17.2813 11.4062 17.2188 11.5312 17.0625L18 10.5C18.2812 10.2187 18.2812 9.78125 18 9.5Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Fail;
