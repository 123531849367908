import React, { useEffect, useState } from "react";
import '../assets/style.css';
import DWT from './DWT/dwt.json'
// Import the file system module to read the JSON file


function findMatchingEntry({
  selectedInvestedMarket, selectedCountry, selectedInvestorType, selectedAssetType, selectedDistributionType
}) {
  // Define the properties to match
  const targetProperties = {
    investedMarket: selectedInvestedMarket,
    assetType: selectedAssetType,
    investorType: selectedInvestorType,
    countryReside: selectedCountry,
    distributionType: selectedDistributionType,
  };

  // Search for a matching entry
  const matchingEntry = DWT.find((entry) => {
    for (const prop in targetProperties) {
      if (entry[prop] !== targetProperties[prop]) {
        return false; // Not a match
      }
    }
    return true; // All properties match
  });

  return matchingEntry;
}


//-----COMPONENT DEFINITIONS---//
const uniqueInvestedMarkets = getUniqueValues(DWT, 'investedMarket');
const uniqueAssetTypes = getUniqueValues(DWT, 'assetType');
const uniqueInvestorTypes = getUniqueValues(DWT, 'investorType');
const uniqueCountries = getUniqueValues(DWT, 'countryReside');
const uniqueDistributionTypes = getUniqueValues(DWT, 'distributionType');

function getUniqueValues(data, property) {
  const valuesSet = new Set(data.map((item) => item[property]));
  const sortedValues = [...valuesSet].sort(); // Sort the values alphabetically
  return sortedValues;
}
// Define the Record component (from the second file)
const Record = (props) => {

    return (
    <div className="w-full">
      <li className="text-body-color mb-4 flex text-base">
        <span className="text-primary mr-2 flex items-center rounded-full text-base">
          <svg width="20" height="8" viewBox="0 0 20 8" className="fill-current">
            <path d="M19.2188 2.90626L17.0625 0.343758C16.875 0.125008 16.5312 0.0937583 16.2812 0.281258C16.0625 0.468758 16.0312 0.812508 16.2188 1.06251L18.25 3.46876H0.9375C0.625 3.46876 0.375 3.71876 0.375 4.03126C0.375 4.34376 0.625 4.59376 0.9375 4.59376H18.25L16.2188 7.00001C16.0312 7.21876 16.0625 7.56251 16.2812 7.78126C16.375 7.87501 16.5 7.90626 16.625 7.90626C16.7812 7.90626 16.9375 7.84376 17.0312 7.71876L19.1875 5.15626C19.75 4.46876 19.75 3.53126 19.2188 2.90626Z"></path>
          </svg>
        </span>
        % Max Rate: {props.record.maxRate}
      </li>
      <li className="text-body-color mb-4 flex text-base">
        <span className="text-primary mr-2 flex items-center rounded-full text-base">
          <svg width="20" height="8" viewBox="0 0 20 8" className="fill-current">
            <path d="M19.2188 2.90626L17.0625 0.343758C16.875 0.125008 16.5312 0.0937583 16.2812 0.281258C16.0625 0.468758 16.0312 0.812508 16.2188 1.06251L18.25 3.46876H0.9375C0.625 3.46876 0.375 3.71876 0.375 4.03126C0.375 4.34376 0.625 4.59376 0.9375 4.59376H18.25L16.2188 7.00001C16.0312 7.21876 16.0625 7.56251 16.2812 7.78126C16.375 7.87501 16.5 7.90626 16.625 7.90626C16.7812 7.90626 16.9375 7.84376 17.0312 7.71876L19.1875 5.15626C19.75 4.46876 19.75 3.53126 19.2188 2.90626Z"></path>
          </svg>
        </span>
        % Treaty Rate: {props.record.treatyRate}
      </li>
      <li className="text-body-color mb-4 flex text-base">
        <span className="text-primary mr-2 flex items-center rounded-full text-base">
          <svg width="20" height="8" viewBox="0 0 20 8" className="fill-current">
            <path d="M19.2188 2.90626L17.0625 0.343758C16.875 0.125008 16.5312 0.0937583 16.2812 0.281258C16.0625 0.468758 16.0312 0.812508 16.2188 1.06251L18.25 3.46876H0.9375C0.625 3.46876 0.375 3.71876 0.375 4.03126C0.375 4.34376 0.625 4.59376 0.9375 4.59376H18.25L16.2188 7.00001C16.0312 7.21876 16.0625 7.56251 16.2812 7.78126C16.375 7.87501 16.5 7.90626 16.625 7.90626C16.7812 7.90626 16.9375 7.84376 17.0312 7.71876L19.1875 5.15626C19.75 4.46876 19.75 3.53126 19.2188 2.90626Z"></path>
          </svg>
        </span>
        % Exempt Rate: {props.record.exemptRate}
      </li>
      <li className="text-body-color mb-4 flex text-base">
        <span className="text-primary mr-2 flex items-center rounded-full text-base">
          <svg width="20" height="8" viewBox="0 0 20 8" className="fill-current">
            <path d="M19.2188 2.90626L17.0625 0.343758C16.875 0.125008 16.5312 0.0937583 16.2812 0.281258C16.0625 0.468758 16.0312 0.812508 16.2188 1.06251L18.25 3.46876H0.9375C0.625 3.46876 0.375 3.71876 0.375 4.03126C0.375 4.34376 0.625 4.59376 0.9375 4.59376H18.25L16.2188 7.00001C16.0312 7.21876 16.0625 7.56251 16.2812 7.78126C16.375 7.87501 16.5 7.90626 16.625 7.90626C16.7812 7.90626 16.9375 7.84376 17.0312 7.71876L19.1875 5.15626C19.75 4.46876 19.75 3.53126 19.2188 2.90626Z"></path>
          </svg>
        </span>
        Relief at Source: {props.record.reliefAtSource}
      </li>
      <li className="text-body-color mb-4 flex text-base">
        <span className="text-primary mr-2 flex items-center rounded-full text-base">
          <svg width="20" height="8" viewBox="0 0 20 8" className="fill-current">
            <path d="M19.2188 2.90626L17.0625 0.343758C16.875 0.125008 16.5312 0.0937583 16.2812 0.281258C16.0625 0.468758 16.0312 0.812508 16.2188 1.06251L18.25 3.46876H0.9375C0.625 3.46876 0.375 3.71876 0.375 4.03126C0.375 4.34376 0.625 4.59376 0.9375 4.59376H18.25L16.2188 7.00001C16.0312 7.21876 16.0625 7.56251 16.2812 7.78126C16.375 7.87501 16.5 7.90626 16.625 7.90626C16.7812 7.90626 16.9375 7.84376 17.0312 7.71876L19.1875 5.15626C19.75 4.46876 19.75 3.53126 19.2188 2.90626Z"></path>
          </svg>
        </span>
        Quick Refund Reclaim: {props.record.quickRefund}
      </li>
    </div>
  );
  }

// Define the SingleSidebarCard component (from its file)
const SingleSidebarCard = ({ title, children }) => {
  return (
    <div className="mb-8 rounded-lg border border-[#e7e7e7] bg-white">
      <div className="border-b border-[#e7e7e7] py-4 px-8 lg:px-6 xl:px-8">
        <h3 className="text-base font-semibold text-black sm:text-lg lg:text-base xl:text-lg">
          {title}
        </h3>
      </div>
      <div className="space-y-4 py-9 px-8 lg:px-6 xl:px-8">{children}</div>
    </div>
  );
};

// Define the CheckboxGroup component (from its file)
const CheckboxGroup = ({ title, id, name, onChange }) => {
  return (
    <div>
      <label
        htmlFor={id}
        className="flex cursor-pointer select-none items-center text-black"
      >
        <div className="relative">
          <input type="radio" id={id} value={title} className="sr-only" name={name} onChange={onChange}/>
          <div className="box mr-4 flex h-5 w-5 items-center justify-center rounded border">
            <span className="opacity-0">
              <svg
                width="11"
                height="8"
                viewBox="0 0 11 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                  fill="#3056D3"
                  stroke="#3056D3"
                  strokeWidth="0.4"
                ></path>
              </svg>
            </span>
          </div>
        </div>
        {title}
      </label>
    </div>
  );
};


//-----END COMPONENT DEFINITIONS---//

export default function RecordListWithDropdown() {
  const [noMatchingRecords, setNoMatchingRecords] = useState(false);

  const [records, setRecords] = useState([]);
  //const [searchValue, setSearchValue] = useState("");
  const [originalRecords, setOriginalRecords] = useState([]);
  const [displayedRecords, setDisplayedRecords] = useState([]);
  const [uniqueAssetTypes, setUniqueAssetTypes] = useState([]); // Initialize as an empty array

  //state variables for dropdown and check box components
  var [selectedInvestedMarket, setSelectedInvestedMarket] = useState("");
  var [selectedAssetType, setSelectedAssetType] = useState("");
  var [selectedInvestorType, setSelectedInvestorType] = useState("");
  var [selectedCountry, setSelectedCountry] = useState("");
  var [selectedDistributionType, setDistributionType] = useState("");

  const [isNoResultsModalOpen, setIsNoResultsModalOpen] = useState(false); // Add state for the modal




  // Fetch records from the server
  useEffect(() => {
      const records = DWT;
      setRecords(records);
      setOriginalRecords(records); // Store original records
      updateAssetTypes(selectedInvestedMarket);
    return;
  }, []);

  const updateAssetTypes = (selectedMarket) => {
    if (!selectedMarket || selectedMarket === "Select market") {
      // If no market is selected or "Select market" is selected, show all asset types
      setUniqueAssetTypes(getUniqueValues(DWT, "assetType"));
    } else {
      // Filter and set unique asset types based on the selected market
      const assetTypes = [...new Set(DWT.filter(entry => entry.investedMarket === selectedMarket).map(entry => entry.assetType))];
      setUniqueAssetTypes(assetTypes);
    }
  };

  //-----FUNCTIONS TO HANDLE COMPONENT CHANGES-----//
  const handleInvestedMarketChange = (event) => {
    selectedInvestedMarket = event.target.value;
    setSelectedInvestedMarket(selectedInvestedMarket);
    updateAssetTypes(selectedInvestedMarket);
  };

  const handleDistributionTypeChange = (event) => {
    selectedDistributionType = event.target.value;
    setDistributionType(selectedDistributionType);
  };

  const handleRadioChange = (event) => {
    selectedCountry = event.target.value;
    setSelectedCountry(selectedCountry);
  };

  const handleInvestorTypeChange = (event) => {
    selectedInvestorType = event.target.value;
    setSelectedInvestorType(selectedInvestorType);
  };

  const handleAssetTypeChange = (event) => {
    selectedAssetType = event.target.value;
    setSelectedAssetType(selectedAssetType);
  };

  //-----END FUNCTIONS TO HANDLE COMPONENT CHANGES-----//


  // Perform search
  const onSearch = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    if (selectedInvestedMarket.trim() === "" || 
        selectedInvestedMarket.trim() === "Choose Market Type" ||
        selectedAssetType.trim() === "" || 
        selectedAssetType.trim() === "Choose Invested Asset" ||
        selectedCountry.trim() === "" ||
        selectedDistributionType.trim() === "" || 
        selectedInvestedMarket === "") 
      {
          setDisplayedRecords([]);
      } else {
        const result = findMatchingEntry({
          selectedInvestedMarket,
          selectedCountry,
          selectedInvestorType,
          selectedAssetType,
          selectedDistributionType,
        });        // Test the function
        // Filter records based on the search value and update the displayed records.
      const newRecords = originalRecords.filter((el) => 
      el.investedMarket === selectedInvestedMarket
      && el.countryReside === selectedCountry 
      && el.investorType === selectedInvestorType
      && el.assetType === selectedAssetType
      && el.distributionType === selectedDistributionType);
      setDisplayedRecords(newRecords);

      if (newRecords.length === 0) {
        setIsNoResultsModalOpen(true); // Show the modal when there are no results
        setNoMatchingRecords(true); // Set to false when there are matching records

      } else {
        setIsNoResultsModalOpen(false); // Hide the modal when there are results
        setNoMatchingRecords(false); // Set to false when there are matching records

      }
    }

  };

  // click event listener for view details button
   const viewDetailsButton = document.getElementById("viewDetailsButton");

  if (viewDetailsButton != null) {
    viewDetailsButton.addEventListener("click", onSearch);
  } 


  // Render the RecordListWithDropdown component
  return (
    <>
      <section className="bg-white pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]">
        <div className="container mx-auto">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4 lg:w-1/2">
            <div className="grid grid-cols-2 gap-4">
            <SingleSidebarCard title="Investor Type">
                <div>
                {uniqueInvestorTypes.map((investor, index) => (
                    <CheckboxGroup
                      key={`investor${index}`}
                      title={investor}
                      id={`investor${index}`}
                      name="investorgroup"
                      checked={selectedInvestorType === investor}
                      onChange={handleInvestorTypeChange}
                    />
                  ))}
                </div>
              </SingleSidebarCard>
              <SingleSidebarCard title="Country of Residence">
                <div>
                {uniqueCountries.map((country, index) => (
                    <CheckboxGroup
                      key={`country${index}`}
                      title={country}
                      id={`country${index}`}
                      name="countrygroup"
                      checked={selectedCountry === country}
                      onChange={handleRadioChange}
                    />
                  ))}
                </div>
              </SingleSidebarCard>
              <SingleSidebarCard title="Invested Market">
                <div className="relative">
                  <select
                    value={selectedInvestedMarket}
                    onChange={handleInvestedMarketChange}
                    className="border-form-stroke text-body-color focus:border-primary 
                  active:border-primary w-full appearance-none rounded-lg border-[1.5px] py-3 px-5 
                  font-medium outline-none transition disabled:cursor-default disabled:bg-[#F5F7FD]"
                  >
                    <option>Select market</option>
                      {uniqueInvestedMarkets.map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                  </select>
                  <span className="border-body-color absolute right-4 top-1/2 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2"></span>
                </div>
              </SingleSidebarCard>
              <SingleSidebarCard title="Distribution type">
                <div className="relative">
                  <select
                    value={selectedDistributionType}
                    onChange={handleDistributionTypeChange}
                    className="border-form-stroke text-body-color focus:border-primary 
                  active:border-primary w-full appearance-none rounded-lg border-[1.5px] py-3 px-5 
                  font-medium outline-none transition disabled:cursor-default disabled:bg-[#F5F7FD]"
                  >
                      <option>Select distribution</option>

                        {uniqueDistributionTypes.map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                  </select>
                  <span className="border-body-color absolute right-4 top-1/2 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2"></span>
                </div>
              </SingleSidebarCard>
              <SingleSidebarCard title="Asset Type">
                <div className="relative">
                  <select
                    value={selectedAssetType}
                    onChange={handleAssetTypeChange}
                    className="border-form-stroke text-body-color focus:border-primary 
                  active:border-primary w-full appearance-none rounded-lg border-[1.5px] 
                  py-3 px-5 font-medium outline-none transition disabled:cursor-default 
                  disabled:bg-[#F5F7FD]">
                      <option>Select asset</option>
                        {uniqueAssetTypes.map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                  </select>
                  <span className="border-body-color absolute right-4 top-1/2 mt-[-2px] h-[10px] w-[10px] -translate-y-1/2 rotate-45 border-r-2 border-b-2"></span>
                </div>
              </SingleSidebarCard>
              <div className="mb-8 rounded-lg  bg-white">
                <div className="space-y-4 py-16 px-8 lg:px-6 xl:px-16">
                    <button className='inline-flex items-center justify-center rounded-md border border-black
                    py-4 px-10 text-center text-base text-black transition hover:border-black hover:bg-black
                      hover:text-white lg:px-8 xl:px-10'
                      id="viewDetailsButton"
                    >
                      View Results
                  </button>
                </div>
              </div>

            </div>
            </div>
            <div className="w-full px-4 lg:w-1/2">
              <div className="mb-8 flex h-full items-center justify-center rounded-lg border border-dashed border-[#e7e7e7] py-12">
                <div>
                  <h3>Results</h3>
                  {noMatchingRecords ? (
                      <p>No records match your search. Please try again.</p>
                    ) : (
                      // Render the list of records when there are matching records
                      displayedRecords.map((record) => (
                        <Record
                          record={record}
                          deleteRecord={() => deleteRecord(record._id)}
                          key={record._id}
                        />
                      ))
                    )}
                </div>
              </div>
            </div>
          </div>
        {/* <NoResultsModal isOpen={isNoResultsModalOpen} onClose={() => setIsNoResultsModalOpen(false)} /> */}
        </div>
      </section>
    </>
  );
}
