import React, { useEffect, useState } from "react";
import axios from 'axios';
import pdf from '../assets/img/pdf.svg';

const headers = [
  { name: 'Headline', styles: 'min-w-[280px]' },
  { name: 'Market', styles: 'min-w-[280px]' },
  { name: 'PDF', styles: 'min-w-[280px]' },
  { name: 'Date', styles: 'min-w-[280px]' },
]

const Table5 = () => {
        const [tableData, setTableData] = useState([]);
        const [error, setError] = useState(null);

        useEffect(() => {
          async function fetchData() {
            try {
              const res = await axios.get('https://grateful-presence-5c73bfcfd5.strapiapp.com/api/announcements?populate=*');
              const sortedData = res.data.data.sort((a, b) => new Date(b.attributes.Date) - new Date(a.attributes.Date));
              setTableData(sortedData);
            } catch (error) {
              setError(error);
            }
          }
      
          fetchData();
        }, []);
      
        if (error) {
          return <div>An error occurred: {error.message}</div>;
        }
    
  return (
    <section id='announcements' className='bg-white py-5 lg:py-[20px]'>
    <div className="container mx-auto text-left">
      <div className="-mx-4 flex flex-wrap">
        <div className="w-full px-4">
          <div className="mb-12 max-w-[510px] lg:mb-5">
            <h2 className="text-dark mb-5 text-3xl font-bold sm:text-4xl md:text-[40px]">
              Latest Headlines & Announcements
            </h2>
            <p className="text-body-color text-base">
              Latest financial news and headlines from the markets we service
            </p>
          </div>
        </div>
      </div>
    </div>
      <div className='container mx-auto'>
        <div className='-mx-4 flex flex-wrap'>
          <div className='w-full px-4'>
            <div className='max-w-full overflow-x-auto rounded-xl shadow-lg'>
              <table className='w-full table-auto'>
                <TableHead headers={headers} />
                <TableBody data={tableData} />
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Table5;

const TableHead = ({ headers }) => {
  return (
    <thead>
      <tr className='bg-primary text-left'>
        {headers.map((header, index) => (
          <th
            className={`py-4 px-4 first:pl-11 last:pr-11 text-base font-semibold text-white ${header.styles}`}
            key={index}
          >
            {header.name}
          </th>
        ))}
      </tr>
    </thead>
  )
}

const TableBody = ({ data }) => {
  return (
    <tbody>
      {data.map((row, index) => (
        <tr key={index}>
          <td className='border-t py-5 px-4 pl-11'>
            <h5 className='text-body-color text-base font-medium'>
              {row.attributes.Headline}
            </h5>
          </td>
          <td className='border-t py-5 px-4'>
            <p className='text-body-color text-base'>{row.attributes.Market}</p>
          </td>
          <td className='border-t py-5 px-4'>
            <a href={row.attributes.PDF.data.attributes.url} onClick={(e) => { e.preventDefault(); window.open(row.attributes.PDF.data.attributes.url); }}>
                <img src={pdf} alt="PDF" />                              
            </a>
          </td>
          <td className='border-t py-5 px-4'>
            <p className='text-body-color text-base'>{row.attributes.Date}</p>
          </td>
        </tr>
      ))}
    </tbody>
  )
}



