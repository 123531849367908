import React from "react";

const Service2 = (
    {
        h2,
        paragraph,
        Card1Title,
        href1,
        Card1Desc,
        Card2Title,
        href2,
        Card2Desc,
        Card3Title,
        href3,
        Card3Desc
    }
) => {
  return (
    <section id="services" className="pt-20 pb-8 lg:pt-[120px] lg:pb-[70px]">
      <div className="container mx-auto text-left">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mb-12 max-w-[510px] lg:mb-20">
              <h2 className="text-dark mb-4 text-3xl font-bold sm:text-4xl md:text-[40px]">
                {h2}
              </h2>
              <p className="text-body-color text-base">
                {paragraph}
              </p>
            </div>
          </div>
        </div>
        <div className="-mx-4 flex flex-wrap">
          <ServiceCard
            title={Card1Title}
            details={Card1Desc}
            btnLink={href1}
            btnText="Learn More"
            icon={
              <svg
                width="31"
                height="36"
                viewBox="0 0 31 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M29.1886 4.68293H28.6546V2.16585C28.6546 0.995122 27.7648 0 26.6376 0H6.70388C5.57668 0 4.68679 0.995122 4.68679 2.16585V4.62439H2.0171C0.889896 4.62439 0 5.50244 0 6.61463V15.5122C0 16.6244 0.889896 17.5024 2.0171 17.5024H18.8065V20.078H17.5013C16.4927 20.078 15.7215 20.8976 15.7215 21.9512V34.1268C15.7215 35.1805 16.5521 36 17.5013 36H22.2474C23.2559 36 24.0272 35.1805 24.0272 34.1268V21.9512C24.0272 20.8976 23.1966 20.078 22.2474 20.078H20.9422V17.4439C20.9422 16.3317 20.0523 15.4537 18.9251 15.4537H2.07642V6.67317H4.68679V9.13171C4.68679 10.3024 5.57668 11.2976 6.70388 11.2976H26.6969C27.8241 11.2976 28.714 10.3024 28.714 9.13171V6.67317H29.2479C29.8412 6.67317 30.3158 6.20488 30.3158 5.61951C30.3158 5.03415 29.7819 4.68293 29.1886 4.68293ZM21.8914 34.0098H17.7386V22.1268H21.8914V34.0098ZM26.5782 9.19024C26.5782 9.24878 26.5782 9.24878 26.5782 9.30732H6.82254C6.82254 9.30732 6.82254 9.24878 6.82254 9.19024V2.16585C6.82254 2.10732 6.82254 2.10732 6.82254 2.04878H26.5782C26.5782 2.04878 26.5782 2.10732 26.5782 2.16585V9.19024Z"
                  fill="white"
                />
              </svg>
            }
          />
          <ServiceCard
            title={Card2Title}
            details={Card2Desc}
            btnLink={href2}
            btnText="Learn More"
            icon={
              <svg
                width="40"
                height="34"
                viewBox="0 0 40 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.4726 10.9106C15.4726 13.3844 17.5024 15.4143 19.9763 15.4143C22.4501 15.4143 24.48 13.3844 24.48 10.9106C24.48 8.4367 22.4501 6.40686 19.9763 6.40686C17.5024 6.47029 15.4726 8.50013 15.4726 10.9106ZM22.1964 10.9106C22.1964 12.1792 21.1815 13.1941 19.9128 13.1941C18.6442 13.1941 17.6293 12.1792 17.6293 10.9106C17.6293 9.64191 18.6442 8.62699 19.9128 8.62699C21.1815 8.62699 22.1964 9.70534 22.1964 10.9106Z"
                  fill="white"
                />
                <path
                  d="M39.006 22.709L36.0247 19.7277C35.7075 19.4105 35.2 19.2837 34.756 19.474L31.4575 20.9329C30.8866 21.1866 30.6329 21.821 30.8866 22.3919C31.1404 22.9628 31.7747 23.2165 32.3456 22.9628L33.6777 22.3919C32.4724 25.5 30.2523 28.0373 27.4613 29.6866V23.3433C27.4613 20.1083 25.1143 17.5076 22.133 17.5076H17.3121C14.3942 17.5076 11.9838 20.1083 11.9838 23.3433V29.4963C9.12933 27.7202 6.90919 24.9292 5.83084 21.5672C5.64055 20.9964 5.00622 20.6792 4.43533 20.8695C3.86444 21.0598 3.54728 21.6941 3.73757 22.265C6.02114 29.306 12.5547 34 19.9128 34C27.1441 34 33.4874 29.4963 35.9612 22.709L37.4202 24.168C37.6105 24.3583 37.9276 24.4851 38.1814 24.4851C38.4351 24.4851 38.7523 24.3583 38.9425 24.168C39.45 23.8508 39.45 23.1531 39.006 22.709ZM14.2674 30.7015V23.4068C14.2674 21.4404 15.6629 19.7911 17.3755 19.7911H19.4688L18.3905 22.8359C18.2636 23.2165 18.2636 23.5971 18.3905 23.9142L19.2785 26.8321C19.4688 27.403 20.23 27.403 20.4203 26.8321L21.3083 23.9142C21.4352 23.5336 21.4352 23.1531 21.3083 22.8359L20.23 19.7911H22.3233C24.0359 19.7911 25.4314 21.3769 25.4314 23.4068V30.8284C23.7188 31.4627 21.8792 31.8433 19.9763 31.8433C17.9464 31.8433 16.0435 31.4627 14.2674 30.7015Z"
                  fill="white"
                />
                <path
                  d="M4.94279 16.1119L8.495 14.209C9.06589 13.8918 9.25619 13.2575 8.93903 12.6866C8.62186 12.1791 7.98754 11.9254 7.41665 12.2426L5.64054 13.1941C7.35322 6.72395 13.189 2.15682 19.9763 2.15682C26.827 2.15682 32.7262 6.78739 34.4388 13.4478C34.5657 14.0187 35.2 14.3993 35.7709 14.2724C36.3418 14.1455 36.7224 13.5112 36.5955 12.9403C34.6926 5.32844 27.8419 0.00012207 19.9763 0.00012207C12.2375 0.00012207 5.51368 5.20158 3.48384 12.6866L2.21519 11.4179C1.77117 10.9739 1.07341 10.9739 0.629384 11.4179C0.185357 11.862 0.185357 12.5597 0.629384 13.0038L3.61071 15.9851C3.801 16.1754 4.11816 16.3022 4.37189 16.3022C4.62562 16.2388 4.75249 16.1754 4.94279 16.1119Z"
                  fill="white"
                />
              </svg>
            }
          />
          <ServiceCard
            title={Card3Title}
            details={Card3Desc}
            btnLink={href3}
            btnText="Learn More"
            icon={
              <svg
                width="33"
                height="36"
                viewBox="0 0 33 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.7425 21.1907H1.68562C0.722411 21.1907 0 22.0335 0 23.0569V34.1339C0 35.1573 0.782612 36.0001 1.68562 36.0001H6.7425C7.70571 36.0001 8.42813 35.1573 8.42813 34.1339V23.0569C8.42813 21.9733 7.64551 21.1907 6.7425 21.1907ZM6.32109 33.8329H2.10703V23.2977H6.32109V33.8329Z"
                  fill="white"
                />
                <path
                  d="M19.0101 18H13.9533C12.99 18 12.2676 18.7826 12.2676 19.806V34.194C12.2676 35.1573 13.0503 36.0001 13.9533 36.0001H19.0101C19.9734 36.0001 20.6958 35.2175 20.6958 34.194V19.806C20.6958 18.7826 19.9132 18 19.0101 18ZM18.5887 33.8328H14.3747V20.107H18.5887V33.8328Z"
                  fill="white"
                />
                <path
                  d="M31.2777 22.756H26.2208C25.2576 22.756 24.5352 23.5988 24.5352 24.6222V34.0738C24.5352 35.0972 25.3178 35.94 26.2208 35.94H31.2777C32.2409 35.94 32.9633 35.0972 32.9633 34.0738V24.6222C33.0235 23.5988 32.2409 22.756 31.2777 22.756ZM30.9165 33.8329H26.7024V24.8028H30.9165V33.8329Z"
                  fill="white"
                />
                <path
                  d="M18.1071 6.02009H14.9165C14.1941 6.02009 13.592 5.47828 13.592 4.75587C13.592 4.09366 14.1941 3.49165 14.9165 3.49165H19.6723C20.2743 3.49165 20.756 3.01005 20.756 2.40804C20.756 1.80603 20.2743 1.32442 19.6723 1.32442H18.0469V1.08362C18.0469 0.481607 17.5653 0 16.9633 0C16.3613 0 15.8797 0.481607 15.8797 1.08362V1.44482H14.9165C12.99 1.44482 11.485 2.94984 11.485 4.81607C11.485 6.6823 13.0502 8.18732 14.9165 8.18732H18.1071C18.8295 8.18732 19.4315 8.72913 19.4315 9.45154C19.4315 10.1138 18.8295 10.7158 18.1071 10.7158H12.689C12.087 10.7158 11.6054 11.1974 11.6054 11.7994C11.6054 12.4014 12.087 12.883 12.689 12.883H15.8797V13.2442C15.8797 13.8462 16.3613 14.3278 16.9633 14.3278C17.5653 14.3278 18.0469 13.8462 18.0469 13.2442V12.883H18.1673C20.0937 12.883 21.5988 11.378 21.5988 9.51174C21.5988 7.64551 19.9733 6.02009 18.1071 6.02009Z"
                  fill="white"
                />
              </svg>
            }
          />
        </div>
      </div>
    </section>
  );
};

export default Service2;

const ServiceCard = ({ icon, title, details, btnText, btnLink }) => {
  return (
    <div className="w-full px-4 md:w-1/2 lg:w-1/3">
      <div className="group mb-12 bg-white">
        <div
          className={`relative z-10 mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl bg-primary`}
        >
          <span
            className={`absolute top-0 left-0 z-[-1] mb-8 flex h-[70px] w-[70px] rotate-[0deg] items-center justify-center rounded-2xl bg-opacity-20 duration-300 group-hover:rotate-45 bg-primary`}
          ></span>
          {icon}
        </div>
        <h4 className="text-dark mb-3 text-xl font-semibold">{title}</h4>
        <p className="text-body-color mb-8 lg:mb-11">{details}</p>
        <a
          href={btnLink}
          className="text-body-color hover:text-primary text-base font-medium"
        >
          {btnText}
        </a>
      </div>
    </div>
  );
};
