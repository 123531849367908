import React from "react";
import logo from "../assets/img/logo.svg";

const Footer2 = () => {
  return (
    <footer className="relative z-10 bg-white "> 
     <div className="border-t py-8">
        <div className="container mx-auto">
          <div className="-mx-4 flex flex-wrap items-center">
            <div className="w-full px-4 lg:w-3/12 xl:w-1/3">
              <div className="w-full py-3 text-center lg:text-left">
                <a href="/#" className="inline-block max-w-[160px]">
                  <img
                    src={logo}
                    alt="Peresec Logo"
                    className="max-w-full"
                  />
                </a>
              </div>
            </div>
            <div className="w-full px-4 md:w-1/2 lg:w-5/12 xl:w-1/3">
              <div className="py-3 text-center">
                <p className="text-body-color text-base">
                Contact us at ops@peresec.com
                <br></br>
                  © {new Date().getFullYear()} Peresec | All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer2;
