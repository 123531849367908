import React, { useState } from 'react';
import Papa from 'papaparse';

const headers = [
    { name: 'Timestamp', styles: 'min-w-[140px]' },
    { name: 'Open', styles: 'min-w-[140px]' },
    { name: 'High', styles: 'min-w-[140px]' },
    { name: 'Low', styles: 'min-w-[140px]' },
    { name: 'Close', styles: 'min-w-[140px]' },
    { name: 'Volume', styles: 'min-w-[140px]' },
];

const itemsPerPage = 10;

const IntraDayTable = ({ tableData, selectedStock }) => {
    const [currentPage, setCurrentPage] = useState(1);

    if (!tableData || tableData.length === 0) {
        return (
            <section>
                <div>
                </div>
            </section>
        );
    }

    const convertToCSV = (data) => {
        const csv = Papa.unparse(data);
        return csv;
    }

    const downloadCSV = () => {
        const csvData = convertToCSV(tableData); // Replace with your data
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'Intra Data.csv'; // Set the desired file name
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }

    const totalItems = tableData.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const paginateData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return tableData.slice(startIndex, endIndex);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <section className='bg-white dark:bg-dark py-20 lg:py-[120px]'>
            <div className='container mx-auto'>
                <div className='-mx-4 flex flex-wrap'>
                    <div className='w-full px-4'>
                        <div className='text-center'>
                            <h1 className="text-dark mb-5 text-3xl font-bold font-hero capitalize leading-snug sm:text-[42px] sm:leading-snug xl:text-[50px] xl:leading-tight">
                                Intraday Data
                            </h1>
                        </div>
                        <div className='max-w-full overflow-x-auto bg-white dark:bg-dark-2 shadow-[0px_3px_8px_0px_rgba(0,0,0,0.08)] rounded-xl'>
                            <div className='flex items-start justify-between p-4'>
                                <div className='flex h-[50px] items-center justify-center '>
                                    <h1>Stock selected: {selectedStock}</h1>
                                </div>
                                <div>
                                    <button
                                        className="flex h-[50px] items-center justify-center rounded-md border border-stroke bg-white px-5"
                                        onClick={downloadCSV}
                                    >
                                        <span>
                                            <path
                                                d='M16.5 2.25L17.2636 2.89573C17.515 2.59845 17.571 2.18229 17.4071 1.82912C17.2433 1.47596 16.8893 1.25 16.5 1.25V2.25ZM1.5 2.25V1.25C1.11067 1.25 0.756741 1.47596 0.592882 1.82912C0.429024 2.18229 0.485033 2.59845 0.73643 2.89573L1.5 2.25ZM7.5 9.345H8.5C8.5 9.10858 8.41623 8.8798 8.26357 8.69928L7.5 9.345ZM7.5 14.25H6.5C6.5 14.6288 6.714 14.975 7.05279 15.1444L7.5 14.25ZM10.5 15.75L10.0528 16.6444C10.3628 16.7994 10.7309 16.7829 11.0257 16.6007C11.3205 16.4184 11.5 16.0966 11.5 15.75H10.5ZM10.5 9.345L9.73643 8.69928C9.58377 8.8798 9.5 9.10858 9.5 9.345H10.5ZM16.5 1.25H1.5V3.25H16.5V1.25ZM0.73643 2.89573L6.73643 9.99073L8.26357 8.69928L2.26357 1.60427L0.73643 2.89573ZM6.5 9.345V14.25H8.5V9.345H6.5ZM7.05279 15.1444L10.0528 16.6444L10.9472 14.8556L7.94721 13.3556L7.05279 15.1444ZM11.5 15.75V9.345H9.5V15.75H11.5ZM11.2636 9.99073L17.2636 2.89573L15.7364 1.60427L9.73643 8.69928L11.2636 9.99073Z'
                                                fill='#637381'
                                            />
                                        </span>
                                        Download CSV
                                    </button>
                                </div>
                            </div>
                            <table className='w-full table-auto'>
                                <TableHead headers={headers} />
                                <TableBody data={paginateData()} />
                            </table>
                            <TableBottom
                                handlePreviousPage={handlePreviousPage}
                                handleNextPage={handleNextPage}
                                currentPage={currentPage}
                                totalPages={totalPages}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

};

const TableBottom = ({ handlePreviousPage, handleNextPage, currentPage, totalPages }) => {
    return (
        <div className='flex items-center justify-between p-5 sm:px-6'>
            <div className='flex items-center justify-end space-x-3'>
                <button
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    className={`flex h-[30px] w-[30px] items-center justify-center rounded border border-stroke bg-[#FAFBFF] text-body-color hover:border-primary hover:bg-primary hover:text-white ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : ''
                        }`}
                >
                    <svg
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <path
                            d='M14.25 10C14.8023 10 15.25 9.55228 15.25 9C15.25 8.44771 14.8023 8 14.25 8L14.25 10ZM3.75 9L3.04289 8.29289C2.65237 8.68342 2.65237 9.31658 3.04289 9.70711L3.75 9ZM8.29289 14.9571C8.68342 15.3476 9.31658 15.3476 9.70711 14.9571C10.0976 14.5666 10.0976 13.9334 9.70711 13.5429L8.29289 14.9571ZM9.7071 4.45711C10.0976 4.06658 10.0976 3.43342 9.7071 3.04289C9.31658 2.65237 8.68342 2.65237 8.29289 3.04289L9.7071 4.45711ZM14.25 8L3.75 8L3.75 10L14.25 10L14.25 8ZM9.70711 13.5429L4.4571 8.29289L3.04289 9.70711L8.29289 14.9571L9.70711 13.5429ZM4.4571 9.70711L9.7071 4.45711L8.29289 3.04289L3.04289 8.29289L4.4571 9.70711Z'
                            fill='currentColor'
                        />
                    </svg>
                </button>
                <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className={`flex h-[30px] w-[30px] items-center justify-center rounded border border-stroke bg-[#FAFBFF] text-body-color hover:border-primary hover:bg-primary hover:text-white ${currentPage === totalPages ? 'cursor-not-allowed opacity-50' : ''
                        }`}
                >
                    <svg
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <path
                            d='M3.75 8C3.19772 8 2.75 8.44772 2.75 9C2.75 9.55229 3.19772 10 3.75 10V8ZM14.25 9L14.9571 9.70711C15.3476 9.31658 15.3476 8.68342 14.9571 8.29289L14.25 9ZM9.70711 3.04289C9.31658 2.65237 8.68342 2.65237 8.29289 3.04289C7.90237 3.43342 7.90237 4.06658 8.29289 4.45711L9.70711 3.04289ZM8.29289 13.5429C7.90237 13.9334 7.90237 14.5666 8.29289 14.9571C8.68342 15.3476 9.31658 15.3476 9.70711 14.9571L8.29289 13.5429ZM3.75 10H14.25V8H3.75V10ZM8.29289 4.45711L13.5429 9.70711L14.9571 8.29289L9.70711 3.04289L8.29289 4.45711ZM13.5429 8.29289L8.29289 13.5429L9.70711 14.9571L14.9571 9.70711L13.5429 8.29289Z'
                            fill='currentColor'
                        />
                    </svg>
                </button>
            </div>
        </div>
    );
};

const TableHead = ({ headers }) => {
    return (
        <thead>
            <tr className='text-left bg-gray dark:bg-dark-3'>
                {headers.map((header, index) => (
                    <th
                        className={`text-body-color dark:text-dark-7 py-4 px-4 first:pl-11 last:pr-11 text-base font-medium uppercase ${header.styles}`}
                        key={index}
                    >
                        {header.name}
                    </th>
                ))}
            </tr>
        </thead>
    );
};

const TableBody = ({ data }) => {
    return (
        <tbody>
            {data.map((row, index) => (
                <tr key={index}>
                    <td className='px-4 py-5 border-t border-stroke dark:border-dark-3'>
                        <p className='text-base text-body-color dark:text-dark-6'>{row.datetime}</p>
                    </td>
                    <td className='px-4 py-5 border-t border-stroke dark:border-dark-3'>
                        <p className='text-base text-body-color dark:text-dark-6'>{row.open}</p>
                    </td>
                    <td className='px-4 py-5 border-t border-stroke dark:border-dark-3'>
                        <p className='text-base text-body-color dark:text-dark-6'>{row.high}</p>
                    </td>
                    <td className='px-4 py-5 border-t border-stroke dark:border-dark-3'>
                        <p className='text-base text-body-color dark:text-dark-6'>{row.low}</p>
                    </td>
                    <td className='px-4 py-5 border-t border-stroke dark:border-dark-3'>
                        <p className='text-base text-body-color dark:text-dark-6'>{row.close}</p>
                    </td>
                    <td className='px-4 py-5 border-t border-stroke dark:border-dark-3'>
                        <p className='text-base text-body-color dark:text-dark-6'>{row.volume}</p>
                    </td>
                </tr>
            ))}
        </tbody>
    )
};

export default IntraDayTable;