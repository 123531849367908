import SEO from './Components/SEO';
import React from 'react'
import Navbar4 from './Components/Nav4';
import Header from './Components/Hero';
import WithholdingTable from './Components/WithholdingTable';
import Footer2 from './Components/Footer'
import Announcements from './Components/Annoucements'


function Tax(){
    return(
        <>
        <SEO
                title={'Withholding tax'}
                description={'Find out the relevant rates of withholding tax per jurisdiction'}
        />
        <Navbar4/>
        <Header 
                title={'Withholding tax'}
                paragraph={'Search for the withholding tax rates that apply to your investments using our search function below'}
            />          
        <WithholdingTable/>
        <Announcements/>
         <Footer2/>
        </>
    )
}

export default Tax;
