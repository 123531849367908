import React from "react";

const Error = () => {
  return (
    <section className="flex min-h-screen items-center justify-center bg-gray py-20 dark:bg-dark lg:py-[120px]">
      <div className="container mx-auto">
        <div className="mx-auto max-w-[600px] rounded-[10px] bg-white p-10 text-center shadow-1 dark:bg-dark-2 dark:shadow-card md:px-[70px] md:py-[55px]">
          <div className="mx-auto mb-14 text-center">
            <div className="mx-auto w-full max-w-full flex h-10 w-20 items-center justify-center rounded-full bg-white/10 text-white">
               <svg
                  width="48"
                  height="48"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  >
                  <g clipPath="url(#clip0_2095_7581)">
                     <path
                        d="M12 0.674988C5.73749 0.674988 0.674988 5.73749 0.674988 12C0.674988 18.2625 5.73749 23.3625 12 23.3625C18.2625 23.3625 23.3625 18.2625 23.3625 12C23.3625 5.73749 18.2625 0.674988 12 0.674988ZM12 21.675C6.67499 21.675 2.36249 17.325 2.36249 12C2.36249 6.67499 6.67499 2.36249 12 2.36249C17.325 2.36249 21.675 6.71249 21.675 12.0375C21.675 17.325 17.325 21.675 12 21.675Z"
                        fill="#F27430"
                        />
                     <path
                        d="M13.5 10.2H10.5C10.05 10.2 9.63751 10.575 9.63751 11.0625V18.5625C9.63751 19.0125 10.0125 19.425 10.5 19.425H13.5C13.95 19.425 14.3625 19.05 14.3625 18.5625V11.0625C14.3625 10.575 13.95 10.2 13.5 10.2ZM12.675 17.7H11.3625V11.8875H12.675V17.7Z"
                        fill="#F27430"
                        />
                     <path
                        d="M12 4.61249C10.725 4.61249 9.63751 5.66249 9.63751 6.97499C9.63751 8.28749 10.6875 9.33749 12 9.33749C13.3125 9.33749 14.3625 8.28749 14.3625 6.97499C14.3625 5.66249 13.275 4.61249 12 4.61249ZM12 7.61249C11.625 7.61249 11.325 7.31249 11.325 6.93749C11.325 6.56249 11.625 6.26249 12 6.26249C12.375 6.26249 12.675 6.56249 12.675 6.93749C12.675 7.31249 12.375 7.61249 12 7.61249Z"
                        fill="#F27430"
                        />
                  </g>
                  <defs>
                     <clipPath id="clip0_2095_7581">
                        <rect width="24" height="24" fill="white" />
                     </clipPath>
                  </defs>
               </svg>
          </div>
          </div>
          <h2 className="mb-3 text-2xl font-semibold text-dark dark:text-white sm:text-3xl">
            Payment error.
          </h2>
          <p className="mb-5 text-base text-body-color dark:text-dark-6">
            This message is to notify you that there was an error with your payment.
            Please retry the payment using the link below or contact Ozow support at clientsupport@ozow.com. 
          </p>
          <a
            href="https://ask.peresec.com/"
            className="inline-flex items-center rounded-md bg-white px-6 py-3 text-base font-medium text-primary shadow-1 hover:bg-gray-2 dark:bg-white/5 dark:shadow-card dark:hover:bg-white/10"
          >
            Go Back To The Payment Page
            <span className="pl-2">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 9.5L11.5312 2.9375C11.25 2.65625 10.8125 2.65625 10.5312 2.9375C10.25 3.21875 10.25 3.65625 10.5312 3.9375L15.7812 9.28125H2.5C2.125 9.28125 1.8125 9.59375 1.8125 9.96875C1.8125 10.3437 2.125 10.6875 2.5 10.6875H15.8437L10.5312 16.0938C10.25 16.375 10.25 16.8125 10.5312 17.0938C10.6562 17.2188 10.8437 17.2813 11.0312 17.2813C11.2187 17.2813 11.4062 17.2188 11.5312 17.0625L18 10.5C18.2812 10.2187 18.2812 9.78125 18 9.5Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Error;
