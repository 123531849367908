import SEO from './Components/SEO'
import Header from './Components/Hero'
import Navbar4 from './Components/Nav4'
import Table from './Components/Table'
import About4 from './Components/CalendarAbout'
import Footer2 from './Components/Footer'
import Announcements from './Components/Annoucements'
import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';

function Holidays(){
    const [tableData, setTableData] = useState([]);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      async function fetchData() {
        try {
          const res = await axios.get('https://grateful-presence-5c73bfcfd5.strapiapp.com/api/bank-holidays');
          const apiData = res.data.data[0].attributes.FY2023;
          const sortedData = apiData.sort((a, b) => new Date(a.Date) - new Date(b.Date));
          setTableData(sortedData);
        } catch (error) {
          setError(error);
        }
      }
    
    
        fetchData();
      }, []);
    return(
        <div>
            <SEO
                title={'Global Bank Holidays 2023'}
                description={'Discover the bank holidays, central bank closures and other key financial holiday dates for 2023'}
            />
            <Navbar4/>
            <Header 
                title={'Global bank holidays'}
                paragraph={'Discover the bank holidays and closures around the world that may impact your trading'}
            />
            <About4
                title={'Bank Holidays'}
                paragraph={'Find out the bank holidays for the 2023 calendar trading year. We include bank closures, central bank closures, stock exchange closures and central depository closures.'}
                h3={'Use cases'}
                bullet1={'Filter all countries to find specific holidays'}
                bullet2={'Find the bank closures for all countries on a specific date'}
                tableData={tableData}
            />
            <Table
                tableData={tableData}
            />
            <Announcements/>
            <Footer2/>
        </div>
    )
}

export default Holidays;