import React, { useEffect, useRef, useState } from 'react';
import ozowLogo from '../src/assets/img/ozow.png';
import crypto from 'crypto-browserify';
//-- GLobal functions start --//


function generateRequestHash(validAmount) {
  const siteCode = "TSTSTE0001";
  const countryCode = "ZA";
  const currencyCode = "ZAR";
  const amount = validAmount;
  const transactionReference = "BradTighy001";
  const bankReference = "TIG001";
  const cancelUrl = "https://ask.peresec.com/OzowFail";
  const errorUrl = "http://test.ozowpay.com/responsetest.php";
  const successUrl = "https://ask.peresec.com/OzowSuccess";
  const notifyUrl = "http://test.i-pay.co.za/responsetest.php";
  const privateKey = "215114531AFF7134A94C88CEEA48E";
  const isTest = true;

  const inputString = `${siteCode}${countryCode}${currencyCode}${amount}${transactionReference}${bankReference}${cancelUrl}${errorUrl}${successUrl}${notifyUrl}${isTest}${privateKey}`;
  console.log("HASH INPUT: " + inputString);
  const calculatedHashResult = generateRequestHashCheck(inputString);
  console.log(`Hashcheck: ${calculatedHashResult}`);

  return calculatedHashResult;
}

function generateRequestHashCheck(inputString) {
  const stringToHash = inputString.toLowerCase();
  console.log(`Before Hashcheck: ${stringToHash}`);
  return getSha512Hash(stringToHash);
}

function getSha512Hash(stringToHash) {
  const hash = crypto.createHash("sha512");
  hash.update(stringToHash);
  return hash.digest("hex");
}

//-- Global functions end --//

const Checkout5 = () => {

  const totalAmountRef = useRef(null);
  var [totalAmountValue, setTotalAmountValue] = useState('');
  const [paymentHash, setPaymentHash] = useState('');


  useEffect(() => {
    const totalAmountElement = totalAmountRef.current;
    if (totalAmountElement) {
      let totalAmountValue = totalAmountElement.innerText.trim();
  
      // Remove the first character
      totalAmountValue = totalAmountValue.slice(1);
  
      // Convert to decimal
      const decimalTotalAmount = parseFloat(totalAmountValue);
  
      // Check if the conversion was successful
      if (!isNaN(totalAmountValue)) {

        var calculatedHashResult = generateRequestHash(decimalTotalAmount);

        setPaymentHash(calculatedHashResult);
        // Update totalAmountValue if needed
        setTotalAmountValue(totalAmountValue);
      }
      
    }
    
     document.getElementById('ozow-btn').addEventListener('click', generatePaymentUrl);
 
  }, [totalAmountValue, paymentHash]);
  
  console.log("TOTAL AMOUNT: " + totalAmountValue);
  console.log("HASH: " + paymentHash);

  useEffect(() => {
    const ozowBtn = document.getElementById('ozow-btn');
    
    if (ozowBtn) {
      ozowBtn.addEventListener('click', generatePaymentUrl);
  
      return () => {
        // Remove the event listener when the component is unmounted
        ozowBtn.removeEventListener('click', generatePaymentUrl);
      };
    }
  }, [generatePaymentUrl]);


  function generatePaymentUrl() {
    const data = {
      countryCode: "ZA",
      amount: totalAmountValue,
      transactionReference: "BradTighy001",
      bankReference: "TIG001",
      cancelUrl: "https://ask.peresec.com/OzowFail",
      currencyCode: "ZAR",
      errorUrl: "http://test.ozowpay.com/responsetest.php",
      isTest: true,
      notifyUrl: "http://test.i-pay.co.za/responsetest.php",
      siteCode: "TSTSTE0001",
      successUrl: "https://ask.peresec.com/OzowSuccess",
      hashCheck: paymentHash,
    };
  
    console.log("PAYMENT HASH: " + paymentHash);
  
    fetch('http://localhost:3001/api/proxy', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'ApiKey': 'EB5758F2C3B4DF3FF4F2669D5FF5B',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
    .then(response => {
      if (!response.ok) {
        console.error('Response status:', response.status);
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log(data);
      if (data.url) {
        // Create an iframe dynamically
        const iframe = document.createElement('iframe');
        iframe.src = data.url;
        iframe.style.width = '100%';
        iframe.style.height = '500px'; // Set the desired height
  
        // Replace the content of the container with the iframe
        const container = document.getElementById('paymentContainer');
        container.innerHTML = '';
        container.appendChild(iframe);
        
      } else {
        // Handle the case when URL is not available in the response
        console.error('URL not found in the response:', data);
      }
    })
    .catch(error => console.error('Fetch error:', error));
  }


  return (
    <>
      <section className="bg-white pb-10 pt-20 dark:bg-dark lg:pb-20 lg:pt-[120px]">
        <div className="container mx-auto">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4 lg:w-12/12">
              <div className="mb-12 lg:mb-0 xl:mr-4 2xl:mr-8">
                <div className="mb-10 overflow-hidden rounded-[10px] border border-stroke px-6 py-10 shadow-testimonial-6 dark:border-dark-3 dark:bg-dark-2 dark:shadow-box-dark sm:px-10">
                  <h4 className="mb-9 text-lg font-semibold text-dark dark:text-white sm:text-2xl lg:text-xl xl:text-2xl">
                    Deposit Page
                  </h4>
                  <div>
                    <div className="mb-5">
                    <p className="text-base">
                    <span className="font-medium">You've requested to make a deposit of </span>
                    <span ref={totalAmountRef} className="font-semibold">R10000</span>
                    <span className="font-medium">. Please select either EFT or Ozow below to complete your payment</span>

                  </p>
                    </div>

                    <h4 className="mb-9 text-lg font-semibold text-dark dark:text-white sm:text-2xl lg:text-xl xl:text-2xl">
                      Payment Details
                    </h4>
                    <div className="-mx-3 mb-9 flex">
                      <div className="px-3">
                        <input
                          type="radio"
                          name="paymentDetails"
                          id="paymentDetails1"
                          className="peer payment sr-only"
                        />
                        <label
                          htmlFor="paymentDetails1"
                          className="flex h-11 cursor-pointer items-center overflow-hidden rounded-md border border-stroke bg-transparent px-6 py-3 text-base font-medium text-dark peer-checked:border-transparent peer-checked:bg-primary/5 peer-checked:shadow-border dark:border-dark-3 dark:text-white"
                        >
                          EFT
                        </label>
                      </div>
                      <div className="px-3">
                        <input
                          type="radio"
                          name="paymentDetails"
                          id="paymentDetails2"
                          className="peer payment sr-only"
                        />
                        <label
                          htmlFor="paymentDetails2"
                          className="flex h-11 cursor-pointer items-center overflow-hidden rounded-md border border-stroke bg-transparent px-6 py-3 text-base font-medium text-dark peer-checked:border-transparent peer-checked:bg-primary/5 peer-checked:shadow-border dark:border-dark-3 dark:text-white"
                        >
                          <img
                            src={ozowLogo}
                            alt="Ozow Logo"
                            className="h-6 w-auto"
                            id="ozow-btn"
                          />
                        </label>
                      </div>
                    </div>
                    <div id="paymentContainer"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  ); 
};

export default Checkout5;
