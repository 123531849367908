import React from 'react'
import PricingTable from './Components/PricingTable'
import Header from './Components/Hero'
import Navbar4 from './Components/Nav4'

function pricing(){
    return(
        <div>
            <Navbar4/>
            <Header 
                title={'Get end of day, intra-day and live trading information from over 150k stocks from 70 markets'}
                paragraph={'High, low, open, close and volume data is available for all listed instruments'}
            />
            <PricingTable/>
        </div>
    )
}

export default pricing;